<template>
  <div class="menu">
    <div style="display: flex;">
      <div class="menu-item">
        <div class="header">
          <div class="title">休假</div>
        </div>
        <div class="body">
          <div class="item" @click="open('annual')">
            <span class="icon"></span>
            <span>年假申请</span>

            <span style="margin-left: auto">
              <YearDetail />
            </span>
          </div>
          <div class="item" @click="open('sick')">
            <span class="icon"></span>
            <span>病假申请</span>
          </div>
          <div class="item" @click="open('personal')">
            <span class="icon"></span>
            <span>事假申请</span>
          </div>
          <div class="item" @click="open('marriage')">
            <span class="icon"></span>
            <span>婚假申请</span>
          </div>
          <template v-if="gender === 'FeMale'">
            <div class="item" @click="open('maternity')">
              <span class="icon"></span>
              <span>产假申请</span>
            </div>
            <div class="item" @click="open('prenatal_check_up')">
              <span class="icon"></span>
              <span>产检假申请</span>
            </div>

            <div class="item" @click="open('breast_feeding')">
              <span class="icon"></span>
              <span>哺乳假申请</span>
            </div>
          </template>
          <template v-if="gender === 'Male'">
            <div class="item" @click="open('paternity')">
              <span class="icon"></span>
              <span>护理假申请</span>
            </div>
          </template>

          <div class="item" @click="open('single_child_carry')">
            <span class="icon"></span>
            <span>独生子女带薪护理假申请</span>
          </div>

          <div class="item" @click="open('childcare')">
            <span class="icon"></span>
            <span>育儿假</span>
          </div>

          <div class="item" @click="open('bereavement')">
            <span class="icon"></span>
            <span>丧假申请</span>
          </div>
          <div class="item danger" @click="openCancelModal('holiday')">
            <span class="icon" style="background-color: #f00"></span>
            <span>撤销申请</span>
          </div>
        </div>
      </div>
      <div class="menu-item">
        <div class="header">
          <div class="title">因公外出</div>
        </div>
        <div class="body">
          <div class="item" @click="open('on_business_in')">
            <span class="icon"></span>
            <span>公出申请</span>
          </div>
          <div class="item danger" @click="openCancelModal('on_business_in')">
            <span class="icon" style="background-color: #f00"></span>
            <span>撤销申请</span>
          </div>
        </div>
      </div>
      <div class="menu-item">
        <div class="header">
          <div class="title">出差</div>
        </div>
        <div class="body">
          <div class="item" @click="open('on_business_out')">
            <span class="icon"></span>
            <span>出差申请</span>
          </div>
          <div class="item warning" @click="openChangeModal('on_business_out')">
            <span class="icon"></span>
            <span>变更申请</span>
          </div>
          <div class="item danger" @click="openCancelModal('on_business_out')">
            <span class="icon" style="background-color: #f00"></span>
            <span>撤销申请</span>
          </div>
          <div class="item" @click="$router.push('timecard/record')">
            <span class="icon"></span>
            <span>出差记录</span>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;">
      <div class="menu-item">
      <div class="header">
        <div class="title">加班</div>
      </div>
      <div class="body">
        <div class="item" @click="open('overtime')">
          <span class="icon"></span>
          <span>加班申请</span>
        </div>
        <div class="item danger" @click="openCancelModal('overtime')">
          <span class="icon" style="background-color: #f00"></span>
          <span>撤销申请</span>
        </div>
      </div>
      </div>
      <div class="menu-item">
        <div class="header">
          <div class="title">调休</div>
        </div>
        <div class="body">
          <div class="item" @click="open('days_off')">
            <span class="icon"></span>
            <span>调休申请</span>
          </div>
          <div class="item danger" @click="openCancelModal('days_off')">
            <span class="icon" style="background-color: #f00"></span>
            <span>撤销申请</span>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      title="请选择"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="800px"
    >
      <a-table
        bordered
        :data-source="filteredList"
        :rowClassName="() => 'clickable'"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  onTableRowClick(record);
                },
              },
            };
          }
        "
      >
        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            <a-tag color="green">{{ getName(text.type) }}</a-tag>
          </template>
        </a-table-column>

        <a-table-column title="日期范围" align="center">
          <template slot-scope="text">
            <span v-if="text.type == 'overtime' || text.type == 'days_off'">
              <div v-for="item in JSON.parse(text.extra)">
                <span style="margin-right: 5px;">{{ item.date }}</span>
                {{ item.startTime }}
                ~
                {{ item.endTime }}
              </div>
            </span>
            <span v-else>
              {{ text.startTime }}
              ~
              {{ text.endTime }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="时长" align="center">
          <template slot-scope="text">
            <span v-if="type=='overtime' || type=='days_off'" style="color: #1890ff">
              {{ text.duration }}分钟
            </span>
            <span v-else style="color: #1890ff"
              >{{ text.duration
              }}{{ text.durationUnit === "hour" ? "小时" : "天" }}</span
            >
          </template>
        </a-table-column>

        <a-table-column title="情况说明" data-index="remark" />
      </a-table>
    </a-modal>

    <a-modal
      title="撤销申请"
      :visible="cancelVisible"
      :footer="null"
      @cancel="cancelCancel"
    >
      <a-textarea v-model="cancelMessage" placeholder="请输入撤销原因" />

      <div class="right" style="margin-top: 16px">
        <a-space>
          <a-button @click="cancelCancel">取消</a-button>
          <a-button type="primary" :loading="cancelLoading" @click="sure"
            >提交</a-button
          >
        </a-space>
      </div>
    </a-modal>
  </div>
</template>
  
<script>
import { addLeave, addAdjust } from "@/api/human-resources/clock";
import { getName } from "@/utils/clock";
import { mapGetters, mapState } from "vuex";
import YearDetail from "./year-detail.vue";

export default {
  components: {
    YearDetail,
  },

  props: {
    leaveList: {
      type: Array,
      default: () => [],
    },
    overtimeList: {
      type: Array,
      default: () => [],
    },
    daysoffList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      type: "",
      visible: false,

      cancelRecord: {},
      cancelMessage: "",
      cancelVisible: false,
      cancelLoading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),

    gender() {
      return this.user && this.user.employeeVo && this.user.employeeVo.sex
        ? this.user.employeeVo.sex
        : "";
    },

    filteredList() {
      if (this.type === "on_business_in") {
        return this.leaveList.filter(
          (item) => item.type === "on_business_in" && item.status === "approved"
        );
      } else if (this.type === "on_business_out") {
        return this.leaveList.filter(
          (item) =>
            item.type === "on_business_out" && item.status === "approved"
        );
      } else if (this.type === "overtime") {
        return this.overtimeList.filter(
          (item) => item.status === "approved"
        );
      } else if (this.type === "days_off") {
        return this.daysoffList.filter(
          (item) => item.status === "approved"
        );
      } else {
        return this.leaveList.filter(
          (item) =>
            item.type !== "on_business_out" &&
            item.type !== "on_business_in" &&
            item.status === "approved"
        );
      }
    },
  },

  methods: {
    open(type) {
      this.$router.push(this.$route.path + "/add?type=" + type);
    },

    openCancelModal(type) {
      this.type = type;
      this.control = "add";
      this.visible = true;
    },
    cancel() {
      this.control = "";
      this.visible = false;
    },

    openChangeModal(type) {
      this.type = type;
      this.control = "change";
      this.visible = true;
    },

    getName,

    onTableRowClick(record) {
      console.log("record", record);

      if (record.userId !== record.createBy) {
        this.$message.error(
          "你不是此次出差的发起人，请联系发起人发起变更或撤销申请"
        );
        return;
      }

      if (this.control === "change") {
        this.$router.push(
          `${this.$route.path}/add?type=${record.type}&id=${record.id}`
        );
        return;
      }

      this.cancelRecord = record;
      this.cancelVisible = true;
    },

    cancelCancel() {
      this.cancelRecord = {};
      this.cancelMessage = "";
      this.cancelVisible = false;
    },

    sure() {
      if (!this.cancelMessage) {
        this.$message.error("请输入撤销原因");
        return;
      }
      this.cancelLoading = true;
      const record = this.cancelRecord;
      if(this.type == 'overtime' || this.type == 'days_off'){
        console.log(record)
        addAdjust({
          pid: record.id,
          userId: record.userId,
          type: record.type,
          optType: "cancel",
          deptId: record.deptId,
          deptName: record.deptName,
          duration: record.duration,
          remark: this.cancelMessage,
          startTime: record.startTime,
          endTime: record.endTime,
          durationUnit: record.durationUnit,
          extra: record.extra,
          attachments: record.attachments,
          name: record.name
        }).then(() => {
          this.cancelCancel();
          this.cancel();
          this.$emit("refresh");
        })
        .finally(() => {
          this.cancelLoading = false;
        });
      }else{
        addLeave({
          name: record.name,
          pid: record.id,
          departureId: record.departureId,
          targetAddrId: record.targetAddrId,
          isAdminOpt: false,
          allUserFlag: false,

          userIdList: [record.userId],

          type: record.type,

          startTime: record.startTime,
          endTime: record.endTime,

          duration: record.duration,
          durationUnit: "day",
          optType: "cancel",
          remark: this.cancelMessage,
          targetAddr: record.targetAddr,
          option: record.option,

          cost: record.cost,
          // staff: record.staff,
          trafficTo: record.trafficTo,
          trafficBack: record.trafficBack,

          staffList: Array.isArray(record.staffList)
            ? record.staffList.map((item) => {
                return {
                  ...item,
                  pid: item.id ? item.id : undefined,
                };
              })
            : undefined,

          attachments: record.attachments,
        })
        .then(() => {
          this.cancelCancel();
          this.cancel();
          this.$emit("refresh");
        })
        .finally(() => {
          this.cancelLoading = false;
        });
      }
    },
  },
};
</script>
  
<style lang="less" scoped>
.menu {
  padding: 12px;
  background-color: #fff;
  gap: 12px;
}

.menu-item {
  border-radius: 4px;
  margin-right: 12px;
  margin-bottom: 12px;
  min-height: 300px;
  .header {
    color: #fff;
    padding: 8px;
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .title {
      font-weight: bold;
      font-size: 14px;
    }

    .extra {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .body {
    min-width: 12vw;
    padding: 10px 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .item {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      font-weight: 400;
      cursor: pointer;

      .icon {
        height: 0.4rem;
        width: 0.4rem;
        border-radius: 50%;
      }
    }
  }
}

.menu-item:first-child {
  background-color: #f1fff0;

  .header {
    background-color: #3ac539;
  }

  .body {
    .item {
      .icon {
        background-color: #3ac539;
      }
    }
  }
}

.menu-item:nth-child(2) {
  background-color: #fff8f0;

  .header {
    background-color: #f4951f;
  }

  .body {
    .item {
      .icon {
        background-color: #f4951f;
      }
    }
  }
}

.menu-item:nth-child(3) {
  background-color: #f0f6ff;

  .header {
    background-color: #4a78ff;
  }

  .body {
    .item {
      .icon {
        background-color: #4a78ff;
      }
    }
  }
}
</style>